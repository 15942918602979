import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseImg extends Vue {
  @Prop({ type: String, required: true }) private readonly src!: string;
  @Prop({ type: [Number, String] }) private readonly maxWidth?: number | string;
  @Prop({ type: [Number, String] }) private readonly width?: number | string;
  @Prop({ type: [Number, String] }) private readonly height?: number | string;
  @Prop({ type: Boolean, default: true }) private readonly lazy!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly contain!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly draggable!: boolean;
  @Prop({ type: [Number, String] }) private readonly aspectRatio?:
    | number
    | string;
}
